import { reactive } from "@stimulus-library/utilities";
import { useMixin } from "./create_mixin";
export function useGeolocation(controller, options = {}, update, error) {
    // Ensure passed functions are bound to the correct controller scope
    if (update) {
        update = update.bind(controller);
    }
    if (error) {
        error = error.bind(controller);
    }
    // Default options to pass to the navigator.geolocation.watchPosition() method
    const { enableHighAccuracy = true, maximumAge = 30000, timeout = 27000, } = options;
    const isSupported = navigator && 'geolocation' in navigator;
    // Create a reactive object to store the geolocation data
    const values = reactive({
        locatedAt: null,
        error: null,
        coords: {
            accuracy: 0,
            latitude: Infinity,
            longitude: Infinity,
            altitude: null,
            altitudeAccuracy: null,
            heading: null,
            speed: null,
        },
        teardown: () => {
            if (watcher) {
                navigator.geolocation.clearWatch(watcher);
                watcher = null;
            }
        },
    });
    let setup = () => {
        if (isSupported) {
            watcher = navigator.geolocation.watchPosition((position) => {
                // Update reactive values
                values.locatedAt = position.timestamp;
                values.coords = position.coords;
                values.error = null;
                // Fire user callback if provided
                if (update) {
                    update(position);
                }
            }, (err) => {
                // Update reactive values
                values.error = err;
                // Fire user callback if provided
                if (error) {
                    error(err);
                }
            }, {
                enableHighAccuracy,
                maximumAge,
                timeout,
            });
        }
    };
    let watcher = null;
    useMixin(controller, setup, values.teardown);
    return values;
}
