import { isElementInViewport } from "@stimulus-library/utilities";
import { useEventListener } from "./use_event_listener";
import { useMixin } from "./create_mixin";
export function useClickOutside(controller, element, callback) {
    callback = callback.bind(controller);
    const handler = (event) => {
        if (element.contains(event.target) || (!isElementInViewport(element))) {
            return;
        }
        callback(event);
    };
    let { teardown } = useEventListener(controller, window, ["click", "touchend"], handler);
    useMixin(controller, () => void 0, teardown);
    return {
        teardown,
    };
}
